<template>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="pagination.loading">
    <template #status="{ record }">
      {{ record.status == 0 ? "待审核" : "已通过" }}
    </template>
    <template #operation="{ record }">
      <a-button v-if="record.status == 0" type="primary" size="small" @click="toAudit(record)">去审核</a-button>
    </template>
  </a-table>
  <a-modal title="审核词条" v-model:visible="modelVisible" :confirm-loading="formLoading" @ok="handleOk"
    @cancel="handlerCancel">
    <a-form ref="formRef" :model="modifyModal" :rules="rulesMod" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-item label="词条名称" name="name">
        <a-input v-model:value="modifyModal.name" />
      </a-form-item>
      <!-- <a-form-item label="所属品牌" name="brandId">
        <a-select v-model:value="modifyModal.brandId" placeholder="请选择">
          <a-select-option
            v-for="item in brandIdArr"
            :key="item.logicId"
            :value="item.logicId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="所属类目" name="goodsType">
        <a-cascader
          v-model:value="modifyModal.goodsType"
          :options="goodsTypeArr"
          :field-names="{ label: 'name', value: 'logicId', children: 'childList' }"
          placeholder="请选择"
          change-on-select
        />
      </a-form-item> -->
      <a-form-item label="关键词1" name="keywords1st">
        <a-input v-model:value="modifyModal.keywords1st" />
      </a-form-item>
      <a-form-item label="关键词2" name="keywords2nd">
        <a-input v-model:value="modifyModal.keywords2nd" />
      </a-form-item>
      <a-form-item label="关键词3" name="keywords3rd">
        <a-input v-model:value="modifyModal.keywords3rd" />
      </a-form-item>
      <a-form-item label="图片地址" name="headPortrait">
        <a-input v-model:value="modifyModal.headPortrait" />
      </a-form-item>
      <a-form-item label="京东地址" name="goodsUrl">
        <a-input disabled v-model:value="modifyModal.goodsUrl" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { page, check, getTypeTree } from "@/api/entry.js";
import { page as loadBrandPage } from "@/api/brand.js";
import { message } from "ant-design-vue";

const columns = [
  { title: "ID", dataIndex: "logicId", width: "15%" },
  { title: "名称", dataIndex: "name", width: "45%" },
  { title: "状态", dataIndex: "status", width: "20%", slots: { customRender: "status" } },
  {
    title: "操作",
    dataIndex: "operation",
    width: "20%",
    slots: { customRender: "operation" },
  },
];
const source = ref([]);
const pagination = ref({
  loading: true,
  position: "bottom",
  total: 0, // 条数
  defaultPageSize: 20, // 默认分页条数
  pageSizeOptions: ["5", "10", "15", "20", "30"],
  showSizeChanger: true, // 是否可以改变 pageSize
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: (total) => `共 ${total} 条`,
  hideOnSinglePage: false, // 只有一页时是否隐藏分页器
  onChange: (selectedRowKeys, selectedRows) => {
    pagination.value.loading = true;
    getList(selectedRowKeys, selectedRows);
  },
});

const formRef = ref();
const modifyModal = ref({
  name: "",
  // brandId: null,
  // goodsTypeOneId: null,
  // goodsTypeTowId: null,
  // goodsTypeThreeId: null,
  keywords1st: "",
  keywords2nd: "",
  keywords3rd: "",
  // goodsType: [],
  headPortrait: "",
  goodsUrl: ""
});
const rulesMod = {
  keywords1st: { required: true, message: "必填项", trigger: "blur" },
};
const brandIdArr = [];
const goodsTypeArr = [];
const formLoading = ref(false);
const modelVisible = ref(false);

const toAudit = (obj) => {
  modifyModal.value = {
    ...obj,
    // goodsType: [obj.goodsTypeOneId, obj.goodsTypeTowId, obj.goodsTypeThreeId],
  };
  modelVisible.value = true;
};
const getList = async (pageNum = 1, pageSize = 20) => {
  const { data, status } = await page({ pageNum, pageSize });
  if (status == "200") {
    const { total, records } = data;
    pagination.value.total = total || 0;
    source.value = records ? records.filter(el => !!el.logicId) : [];
  }
  pagination.value.loading = false;
};
const handleOk = () => {
  formRef.value.validate().then(async () => {
    formLoading.value = true;
    const params = {
      name: modifyModal.value.name,
      headPortrait: modifyModal.value.headPortrait,
      // brandId: modifyModal.value.brandId,
      // goodsTypeOneId: modifyModal.value.goodsType[0],
      // goodsTypeTowId: modifyModal.value.goodsType[1],
      // goodsTypeThreeId: modifyModal.value.goodsType[2],
      keywords1st: modifyModal.value.keywords1st,
      keywords2nd: modifyModal.value.keywords2nd,
      keywords3rd: modifyModal.value.keywords3rd,
      logicId: modifyModal.value.logicId,
    };
    const { msg, status } = await check(params);
    if (status == 200) {
      message.success(msg);
      formLoading.value = false;
      modelVisible.value = false;
      getList();
    } else {
      formLoading.value = false;
      message.error(msg);
    }
  });
};
const handlerCancel = () => {
  modelVisible.value = false;
  modifyModal.value = {
    name: "",
    // brandId: null,
    // goodsTypeOneId: null,
    // goodsTypeTowId: null,
    // goodsTypeThreeId: null,
    keywords1st: "",
    keywords2nd: "",
    keywords3rd: "",
    // goodsType: [],
    headPortrait: "",
    goodsUrl: "",
  };
};

const loadBrand = async () => {
  const { data, status } = await loadBrandPage({ pageNo: 1, pageSize: 999 });
  if (status == 200) {
    brandIdArr = data.dataOnThisPage || [];
  }
};

const loadTree = async () => {
  const { data, status } = await getTypeTree();
  if (status == 200) {
    goodsTypeArr = data;
  }
};

onMounted(() => {
  getList();
  // loadBrand();
  // loadTree();
});
</script>
<style></style>
