import { request, requestJSON } from "../utils/axios";
import { stringify } from "qs";

// 分页查询需要审核的词条
export function page(params) {
  return request({
    url: `/wuan/admin/star/vocabulary/page?${stringify(params)}`,
    method: "get",
  });
}

// 审核词条
export function check(params) {
  return requestJSON({
    url: `/wuan/admin/star/vocabulary/check`,
    method: "post",
    data: params,
  });
}

// 分类树接口
export function getTypeTree() {
  return request({
    url: `/post/type/tree`,
    method: "get",
  });
}
